/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable react-hooks/rules-of-hooks */

import { useGetMock } from 'core/api/mocks';
import { smartMockApi } from 'core/api';
import { useGetMember } from 'core/api/client/MemberApi';
import GetMemberMockData from 'core/sitecore/member/fallback';

export default smartMockApi({
	useGetMember: {
		live: useGetMember,
		mock: () => useGetMock({ payload: GetMemberMockData }),
	},
});

/* eslint-disable-next-line no-restricted-imports */
import React from 'react';
import { uid } from 'react-uid';
import { template } from 'lodash';

import { useAppContext } from 'core/hooks';
import LayoutPadding from 'core/components/LayoutPadding';
import { LIVE_ASSIST_BUTTON_TEXT } from 'core/constants';

import Cta from 'core/components/Cta';

import * as S from './UnauthenticatedFooter.style';
import { IFields } from '../../Footer.sitecore';

interface IProps {
	fields: IFields;
}

const UnauthenticatedFooter = ({ fields }: IProps) => {
	const { footerLinksUnAuth } = fields;

	if (
		typeof fields?.footerLinksUnAuth === 'undefined' ||
		!fields?.footerLinksUnAuth
	) {
		console.log(
			'[for monitoring] - unauth footer fields: ',
			fields?.footerLinksUnAuth,
		);
	}

	const { handleLivePersonModal } = useAppContext();

	const footerYearText = template(fields?.copyrightText?.value)({
		currentYear: new Date().getFullYear(),
	});

	return (
		<S.Wrapper data-target-id="footer--unauthenticated">
			<LayoutPadding>
				<S.List>
					{footerLinksUnAuth &&
						footerLinksUnAuth.map(({ id, fields }) => {
							const linkText = fields?.footerLink?.value?.text;

							return linkText !== undefined ? (
								<S.ListItem key={uid(linkText, Number(id))}>
									{linkText === LIVE_ASSIST_BUTTON_TEXT ? (
										<Cta
											href="javascript:void(0);"
											ctaType="unstyled"
											data-target-id="footer--unauthenticated--link"
											onClick={() => {
												handleLivePersonModal(true);
											}}
										>
											{linkText}
										</Cta>
									) : (
										<Cta
											jssLink={fields?.footerLink}
											ctaType="unstyled"
											data-target-id="footer--unauthenticated--link"
											target="_blank"
										/>
									)}
								</S.ListItem>
							) : (
								console.error(
									`[For monitoring] Footer UnAuth - linktext value is ${linkText}`,
								)
							);
						})}
					<S.ListItem data-target-id="footer--unauthenticated--copyright">
						{fields?.copyrightText === undefined &&
							console.error(
								`[For monitoring] Footer UnAuth - copyrightText value is ${fields?.copyrightText}`,
							)}
						{footerYearText}
					</S.ListItem>
				</S.List>
			</LayoutPadding>
		</S.Wrapper>
	);
};

export default UnauthenticatedFooter;

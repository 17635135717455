/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/camelcase */
import config from 'jss-boilerplate/temp/config.js';

import { useMemberContext } from 'core/hooks';
import { PHONE_NUMBERS_TYPE } from 'core/constants';

import api from './api';

export const useGetMemberData = (isAuthenticated = false) => {
	const base = `${config.australianSuperApi?.apiDomain}${config.australianSuperApi?.apiPathMember}`;
	const memberContext = useMemberContext() ?? {};

	const {
		data: contactDetailsObject,
		loading,
		error,
		refetch,
	} = api.useGetMember({
		base,
		party_type_id: memberContext.party_type_id ?? '',
		lazy: !isAuthenticated,
	});

	const mobileObj = contactDetailsObject?.data?.phone_numbers?.find(
		phone =>
			phone.type === PHONE_NUMBERS_TYPE.MOBILE &&
			phone.number !== '' &&
			phone.number !== null,
	);

	const mobileNumberApiResponse = !!mobileObj ? mobileObj?.number : undefined;
	const emailAddressApiResponse =
		contactDetailsObject?.data?.email_address ?? '';

	return {
		mobileNumberApiResponse,
		emailAddressApiResponse,
		contactDetailsObject,
		loading,
		error,
		refetch,
	};
};

export enum EVENT_TRACKERS {
	CLICK_BUTTON = 'click button',
	CLICK_LINK = 'click link',
	CLICK_INPUT = 'click input',
	START = 'start',
	VIEW = 'view',
	SCREEN_LOAD = 'screen load',
	ON_ERROR = 'on error',
	EDIT = 'edit',
	EDIT_ADDRESS = 'edit address',
	EDIT_EMAIL = 'edit email',
	EDIT_PHONE = 'edit phone',
	EDIT_MOBILE = 'edit mobile',
	CANCEL = 'cancel',
	CANCEL_EMAIL = 'cancel email',
	CANCEL_PHONE = 'cancel phone',
	CANCEL_MOBILE = 'cancel mobile',
	CANCEL_ADDRESS = 'cancel address',
	CLOSE = 'close',
	ERROR = 'error',
	REFRESH = 'refresh',
	EMAIL = 'email',
	PHONE = 'phone',
	ADDRESS = 'address',
	SPOUSE_CONTRIBUTIONS = 'spouse contributions',
	RECEIVE_SPOUSE_CONTRIBUTIONS = 'receive spouse contributions',
	INPUT_NAME = 'input name',
	INPUT_MOBILE = 'input mobile',
	INVALID_MOBILE = 'invalid mobile',
	INPUT_EMAIL = 'input email',
	BEFORE_SHARING_TOOLTIP = 'before sharing tool tip',
	MESSAGE_OVER_75 = 'message - you are not eligible (over 75)',
	MESSAGE_NO_TFN = 'message - tfn not supplied',
	GO_TO_HOME = 'go to home',
	SHOW_PASSWORD = 'show password',
	SHOW_CONFIRM_PASSWORD = 'show confirm password',
	SUBMIT_FORM = 'submit form',
}

export enum EVENT_SECTIONS {
	CONTRIBUTIONS = 'contributions',
	SECURITY = 'security',
}

export enum EVENT_TYPE {
	OTP = 'one time passcode',
	LOGIN = 'login',
}

export enum EVENT_TITLE {
	SEND_CODE = "we'll send a verification code - authenticated or unauthenticated",
	SEND_AUTH_CODE = "we'll send a verification code - authenticated",
	CONTACT_DETAILS = 'contact details',
	MOBILE = 'mobile',
}

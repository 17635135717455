/* eslint-disable @typescript-eslint/camelcase */
import { Member } from 'core/interface/MemberAccount';

const Ashley: Member = {
	party_type_id:
		'EA1610381710B8FEEAC2177D3C3F5CBF26732D2299BED27B93F6022E73D16EE9B1CE84A4D2921A169472D8612DE83B0C6B5E2E6C95A7686332136FE8685AEB72',
	customer_reference_number: '31075195',
	salutation: 'MS',
	first_name: 'Ashley',
	last_name: 'Doe',
	birth_date: '1975-10-11',
	date_of_death: '2020-10-11',
	classification_id: '700',
	first_web_portal_login: true,
	age: 74,
	email_address: 'ashley.doe@email.com',
	member_status: 'ACTIVE',
	gender: '',
	accounts: [
		{
			account_number: '700069092',
			product_name: 'Accumulation',
			product_classification: 'Accumulation',
			account_status: 'ACTIVE',
			joined_date: '2007-07-08',
		},
		{
			account_number: '700069093',
			product_name: 'Pension',
			product_classification: 'TTR',
			account_status: 'ACTIVE',
			joined_date: '2006-07-08',
		},
		{
			account_number: '700069091',
			product_name: 'Pension',
			product_classification: 'Pension',
			account_status: 'ACTIVE',
			joined_date: '2008-07-08',
		},
	],
	phone_numbers: [
		{
			type: 'MOBILE',

			number: '432765981',
		},
		{
			type: 'HOME',

			number: '80329584',
		},
		{ type: 'WORK', number: '52767676' },
	],
	addresses: [
		{
			type: 'RESIDENTIAL',
			address_line1: '50 Lonsdale Street',
			address_line2: '',
			postcode: '3000',
			city: 'Melbourne',
			state: 'VIC',
			country: 'Australia',
			status: 'active',
		},
		{
			type: 'POSTAL',
			address_line1: '50 Lonsdale Street Postal',
			address_line2: '',
			postcode: '3000',
			city: 'Melbourne',
			state: 'VIC',
			country: 'Australia',
			status: 'active',
		},
	],
};

export default Ashley;
